.App {
  text-align: center;
}

.display-flex {
  position: flex;
}

.justify-content-center {
  justify-content: center;
}

.position-absolute {
  position: absolute;
}

.video-display {
  position: "absolute";
  margin-left: "auto";
  margin-right: "auto";
  text-align: "center";
  z-index: 9;
}